<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import socket from '../socket';
import AccountHeader from './views/AccountHeader.vue';

export default defineComponent({
  name: 'AccountsList',
  components: {
    AccountHeader,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);

    const accounts = computed(() => store.state.accounts);
    // const accounts = [
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    //   {
    //     avatar: 'placeholder.png',
    //     description: '1',
    //     codeWait: '1',
    //     status: 7,
    //     number: '+6285142944377',
    //     active: true,
    //   },
    // ];

    return {
      accounts,
      user,
    };
  },
  data() {
    return {
      selfAccountAdded: false,
      selectAll: false,
      buttonText: 'add_an_account',
      buttonClass: 'add-account',
      bloomClass: 'bloom',
      buttonImg: require('@/assets/addAccount.svg'),
    };
  },
  mounted() {},
  computed: {
    activeAccountsCount() {
      return this.accounts.filter((account) => account.active).length;
    },
  },
  methods: {
    getTextManage(account) {
      if (account.status < 3) {
        return 'enter_the_code';
      } else if (account.status == 4) {
        return 'enter_2FA';
      } else if (
        account.status == 7 ||
        account.status == 8 ||
        account.status == 10
      ) {
        return 'management';
      } else {
        return 'delete';
      }
    },
    toggleAll() {
      this.accounts.forEach((account) => {
        // if (account.status === 7 || account.status === 8) {
        //   account.checked = this.selectAll;
        // } else {
        //   account.checked = false;
        // }
        account.checked = this.selectAll;
      });

      this.updateButtonState();
    },
    updateSelectAll() {
      this.selectAll = this.accounts.every((account) => {
        if (account.status === 7 || account.status === 8) {
          account.checked == true;
        }
      });

      this.updateButtonState();
    },
    updateButtonState() {
      const anyChecked = this.accounts.some((account) => account.checked);
      if (anyChecked) {
        this.buttonText = 'account_management';
        this.buttonClass = 'manage-account';
        this.bloomClass = 'blue-bloom';
        this.buttonImg = require('@/assets/manage.svg');
      } else {
        this.buttonText = 'add_an_account';
        this.buttonClass = 'add-account';
        this.bloomClass = 'bloom';
        this.buttonImg = require('@/assets/addAccount.svg');
      }
    },
    manageAccount(account) {
      const number = account.number;
      if (account.status < 3 && account.codeWait) {
        const windowName = 'isCodeInputWindowVisible';
        this.$store.dispatch('setWindowVisibility', {
          windowName,
          value: number,
        });
      } else if (account.status == 4) {
        const windowName = 'isPasswordInputWindowVisible';
        this.$store.dispatch('setWindowVisibility', {
          windowName,
          value: number,
        });
      } else if (account.status == 7 || account.status == 8) {
        this.$router.push({ name: 'settingsPage', params: { number } });
      } else if (
        account.status == 3 ||
        account.status == 9 ||
        account.status == 5 ||
        account.status == 6
      ) {
        socket.emit(
          'deleteAccount',
          window.Telegram.WebApp.initData,
          account.number
        );
      }
    },
    handleActivateAccount(account) {
      socket.emit('changeAccount', window.Telegram.WebApp.initData, account);
    },
    handleButtonClick(number) {
      if (number) {
        this.$router.push({ name: 'settingsPage', params: { number } });
      } else if (this.buttonText === 'add_an_account') {
        const windowName = 'isPhoneInputWindowVisible';
        this.$store.dispatch('setWindowVisibility', {
          windowName,
          value: true,
        });
      } else {
        this.handleMultipleAccounts();
      }
    },
    addSlots() {
      const windowName = 'isSlotsWindowVisible';
      this.$store.dispatch('setWindowVisibility', { windowName, value: true });
    },
    handleMultipleAccounts() {
      const selectedAccounts = this.accounts
        .filter((account) => account.checked)
        .map((account) => account.number);

      if (selectedAccounts.length > 0) {
        const number = JSON.stringify(selectedAccounts);

        this.accounts.forEach((account) => (account.checked = false));

        this.$router.push({
          name: 'settingsPage',
          params: { number },
        });
      }
    },
  },
});
</script>

<template>
  <account-header />
  <div class="content">
    <div class="top-content">
      <div class="left-content">
        <p class="accs">{{ $t('accounts') }}</p>
        <label class="custom-checkbox">
          <input
            type="checkbox"
            v-model="selectAll"
            @change="toggleAll"
          />
          <span :class="{ checked: selectAll }"></span>
          <p
            class="select-all"
            :class="{ 'checked-all-text': selectAll }"
          >
            {{ $t('select_all') }}
          </p>
        </label>
      </div>
      <div
        class="widget"
        @click="addSlots"
      >
        <div class="widget-top">
          <div class="widget-top-left">
            <img
              class="people"
              src="@/assets/people.svg"
            />
            <p class="people-slots">
              {{ activeAccountsCount }}/<span
                style="color: rgba(0, 148, 255, 1)"
                >{{ user.slots }}</span
              >
            </p>
          </div>
          <button class="plus">+</button>
        </div>
        <p class="label-slots">{{ $t('slots') }}</p>
      </div>
    </div>

    <div class="accounts">
      <div
        v-for="(account, index) in accounts"
        :key="index"
        class="account"
      >
        <label class="custom-checkbox">
          <input
            type="checkbox"
            v-model="account.checked"
            @change="updateSelectAll"
            :disabled="account.status !== 7 && account.status !== 8"
          />
          <span
            :class="{
              checked:
                account.status < 7 || account.status > 8
                  ? null
                  : account.checked,
            }"
          ></span>
          <div class="account-info">
            <p class="number">{{ account.number }}</p>
            <p class="status">
              {{
                account.description !== 'ready_to_work'
                  ? $t(account.description)
                  : account.active === true
                  ? $t('works')
                  : $t('ready_to_work')
              }}
            </p>
          </div>
        </label>
        <div class="account-right">
          <button
            class="manage-acc"
            @click="manageAccount(account)"
          >
            {{ $t(getTextManage(account)) }}
          </button>
          <label class="switch">
            <input
              type="checkbox"
              v-model="account.active"
              :disabled="account.status < 7 || account.status > 8"
              @change="handleActivateAccount(account)"
            />
            <span class="slider"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-btn">
    <button
      :class="buttonClass"
      @click="handleButtonClick(false)"
    >
      {{ $t(buttonText) }}
      <img
        class="button-icon"
        :src="buttonImg"
      />
    </button>
    <div :class="bloomClass"></div>
  </div>
</template>

<style scoped>
.content {
  height: 80vh;
  padding: 20px;
  overflow: hidden;
  touch-action: none !important;
}

.top-content {
  display: flex;
  justify-content: space-between;
}

.accs {
  color: #fff;
  font-family: 'Mont-SemiBold';
  font-size: 26px;
  margin-bottom: 10px;
}
/*  */
/* стили для активного чекбокса (при нажатии на него) */
/* для элемента input c type="checkbox" */
.custom-checkbox {
  display: flex;
  align-items: center;
}
.custom-checkbox > input {
  display: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox > span::before {
  content: '';
  /* position: absolute; */
  padding: 2px 10px;
  /* height: 10px; */
  /* width: 20px; */
  /* min-width: 20px; */
  margin-right: 10px;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox > input:focus:not(:checked) + span::before {
  border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox > input:checked + span::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox > input:disabled + span::before {
  background-color: #e9ecef;
}
/*  */
.select-all {
  font-family: 'Mont-Light';
  color: rgba(246, 246, 246, 1);
  /* margin-left: 30px; */
}

.checked-all-text {
  color: #0094ff;
}

.checkbox-all {
  line-height: 1.3;
  cursor: pointer;
  display: flex;
}

.account-info {
  /* margin-left: 30px; */
}

.plus {
  border-radius: 50%;
  border: none;
  height: 24px;
  width: 24px;
  padding: 0;
  font-size: 20px;
  font-family: 'Mont';
  line-height: 1.3;
  cursor: pointer;
  color: rgb(0, 148, 255);
  background-color: #282828;
}

.widget {
  border-radius: 12px;
  padding: 7px 10px;
  width: 130px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 0.5px solid rgba(50, 50, 50, 1);
}

.widget-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.widget-top-left {
  display: flex;
  align-items: stretch;
}

.people {
  margin-right: 8px;
  width: 16px;
}

.people-slots {
  font-family: 'Mont-SemiBold';
  font-size: 16px;
  color: rgba(246, 246, 246, 1);
}

.switch {
  position: relative;
  display: inline-block;
  width: 57px;
  min-width: 57px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #323232;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fa3c00;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translateX(23px);
  background-color: #4fd000;
}

input:disabled + .slider:before {
  background-color: #ccc !important;
}

.accounts {
  height: 55vh;
  margin-top: 30px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.account {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.account-left {
  display: flex;
  cursor: pointer;
}

.account-right {
  display: flex;
  margin-left: 12px;
}

.label-slots {
  font-family: 'Mont-Light';
  opacity: 0.8;
  font-size: 13px;
  color: rgba(246, 246, 246, 1);
}

.number {
  font-family: 'Mont-SemiBold';
  color: rgba(246, 246, 246, 1);
  font-size: 14px;
}

.status {
  color: rgba(246, 246, 246, 1);
  font-size: 9px;
  font-family: 'Mont-Light';
  margin-top: 3px;
}

.manage-acc {
  border-radius: 10px;
  background: linear-gradient(112.66deg, #111111 5.54%, #1e1e1e 121.65%);
  border: 0.5px solid rgba(50, 50, 50, 1);
  color: rgba(255, 255, 255, 1);
  font-family: 'Mont-SemiBold';
  font-size: 10px;
  height: 34px;
  margin-right: 10px;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
}

.blue-bloom {
  width: 100%;
  height: 50px;
  margin-bottom: -10px;
  background: radial-gradient(circle, rgb(0 76 255 / 34%) 0%, transparent 60%);
  position: relative;
  z-index: 5;
  transition: background 0.2s;
}

.bloom {
  width: 100%;
  height: 50px;
  margin-bottom: -10px;
  background: radial-gradient(circle, rgb(255 199 0 / 20%) 0%, transparent 60%);
  position: relative;
  z-index: 5;
  transition: background 0.2s;
}

.add-account {
  display: flex;
  width: -webkit-fill-available;
  height: 60px;
  z-index: 10;
  font-family: Mont;
  font-size: 100%;
  font-weight: 800;
  color: rgba(30, 30, 30, 1);
  background: rgba(255, 199, 0, 1);
  border: none;
  border-radius: 21px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px -10px 20px 0px #000000;
}

.manage-account {
  display: flex;
  width: -webkit-fill-available;
  height: 60px;
  z-index: 10;
  font-family: Mont;
  font-size: 100%;
  font-weight: 800;
  color: white;
  background-color: #0094ff;
  border: none;
  border-radius: 21px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px -10px 20px 0px #000000;
  transition: background-color 0.2s;
}

.button-icon {
  width: 23px;
  margin-top: -5px;
  margin-left: 16px;
}

.bottom-btn {
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
  background: #0a0a0a;
  border-radius: 21px 21px 0 0;
}
</style>
